@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;800&display=swap');
@import url('normalize.css');
@import url('../node_modules/purecss/build/pure.css');
@import url('../node_modules/purecss/build/grids-responsive.css');

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  font-family: 'Nunito Sans', sans-serif;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  color: #090909;
  font-size: 17px;
}


nav {
  position: absolute;
  right: 0;
  text-align: right;
  padding: 2em;
}
nav .pure-menu-list {

}
nav .pure-menu-list .pure-menu-item a {
  color: #090909;
  text-decoration: none;
  padding: 0.5em;
}

@media screen and (min-width: 48em) {
  .container {
    max-width: 700px;
    margin: auto;
    width: 50%;
    min-width: 640px;
  }
}

h1, h2 {
  text-align: center;
}

h1 {
  color: #fec700;
  font-weight: 800;
  text-transform: lowercase;
  font-size: 2.8em;
  margin-top: 1.4em;
}
  h1 a {
    color: #fec700;
    text-decoration: none;
  }

@media screen and (max-width: 48em) {
  body {
    font-size: 13px;
    padding: 5px;
  }

  h1 {
    margin-top: 2em;
  }
}

h2 {
  font-size: 1.35em;
  font-weight: 400;
  margin-top: 2.7em;
}

h3 {
  font-weight: 400;
}

.container .image {
  margin-top: 3em;

}
  .container .image img {
  }

.container figure {
  margin: 2em 0 0;
}
.container blockquote {
  font-size: 175%;
  font-weight: 800;
  color: #fec700;
  margin: 0;
  text-align: center;
  line-height: 1.1em;
}
.container figcaption {
  text-align: center;
  font-size: 1.35em;
  margin-top: 0.1em;
}

.container div.content {
  margin: 4em 0;
  font-size: 120%;
}
.container div p {
  line-height: 1.2em;
}

#about {
  text-align: center;
}
  #about > div {
    display: inline-block;
    text-align: left;
    max-width: 1200px;
  }

.about {
  padding: 1em;
}
  .about a {
    color: black;
    text-decoration: none;
  }

.qualifications {
  padding: 300px 1em 1em;
}

  .qualifications ul {
    list-style: none;
    padding: 0;
  }

    .qualifications ul li {
      padding: 0.6em 0;
    }
  .qualifications ul li h4 {
    font-weight: 400;
    margin: 0;
  }
@media screen and (max-width: 35.5em) {
  .about {
    padding: 2em;
  }
  .qualifications {
    padding: 2em;
  }
}

span.avoidwrap { display:inline-block; }
